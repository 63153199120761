import { isEmpty, reject } from 'ramda';

const env = process.env.NEXT_PUBLIC_PROJECT_ENV;

let baseUrl = process.env.NEXT_PUBLIC_URL;
if (env === 'production') {
  // Temporary ÑAPA: Force production's base URL to ironhack.com
  baseUrl =
    baseUrl && baseUrl.includes('vercel.app')
      ? 'https://www.ironhack.com'
      : baseUrl;
}
if (env === 'preview') {
  const vercelUrl = process.env.VERCEL_URL;
  baseUrl =
    baseUrl ||
    (vercelUrl &&
      (vercelUrl.startsWith('http') ? vercelUrl : `https://${vercelUrl}`));
}

export default {
  abTasty: { scriptSrc: process.env.AB_TASTY_SCRIPT_SRC as string },
  baseUrl,
  bugsnag: { apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY },
  datocms: {
    fullAccessToken: process.env.DATOCMS_FULL_ACCESS_TOKEN,
    readToken: process.env.NEXT_PUBLIC_DATOCMS_READ_TOKEN,
    previewSecret: process.env.DATOCMS_PREVIEW_SECRET,
    environment: process.env.NEXT_PUBLIC_DATOCMS_ENVIRONMENT,
    webhookSecret: process.env.DATOCMS_WEBHOOK_SECRET,
  },
  env,
  gaconnector: {
    accountId: process.env.NEXT_PUBLIC_GACONNECTOR_ACCOUNT_ID,
  },
  google: {
    clientId:
      '676500801588-v1fpojke5mvknngkf33c3ajcafle6kdq.apps.googleusercontent.com',
  },
  gtm: {
    id: 'GTM-P26TMM',
    serverUrl: 'https://sgtm.ironhack.com',
    environment: reject(isEmpty, {
      gtmAuth: '',
      gtmPreview: '',
    }),
  },
  keycloak: {
    host: 'https://account.ironhack.com',
    clientId: 'website',
    clientSecret: process.env.KEYCLOAK_CLIENT_SECRET,
  },
  salesforce: {
    baseUrl: process.env.SALESFORCE_HOST,
    clientId: process.env.SALESFORCE_CLIENT_ID,
    clientSecret: process.env.SALESFORCE_CLIENT_SECRET,
    refreshToken: process.env.SALESFORCE_REFRESH_TOKEN,
  },
  seolyzer: {
    id: process.env.SEOLYZER_SITE_ID,
  },
  userService: {
    baseUrl: 'https://user-service.ironhack.tech/v1',
    apiKey: process.env.USER_SERVICE_API_KEY,
  },
  vercel: {
    apiToken: process.env.VERCEL_API_TOKEN as string,
    edgeConfigEndpoint: process.env.VERCEL_EDGE_CONFIG_ENDPOINT as string,
  },
};
