import { equals, keys } from 'ramda';
import { useEffect } from 'react';

import { usePollingEffect } from '@/hooks';
import {
  type AbTastyTestsOnPage,
  handleAbTastyImpression,
} from '@/lib/ab-tasty';

export const useAbTastyTests = (pathname: string): void => {
  // When the page changes, AB Tasty reports about tests after a delay.
  // To work around that, we monitor the value of getTestsOnPage().
  const abTastyTests = usePollingEffect<AbTastyTestsOnPage | undefined>(
    () => {
      if (typeof window === 'undefined' || !window.ABTasty) return;
      return window.ABTasty.getTestsOnPage();
    },
    (a, b) => equals(keys(a), keys(b)),
    1000
  );

  useEffect(() => {
    handleAbTastyImpression(pathname, abTastyTests || {});
    // We don't want pathname changes to trigger extra impressions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abTastyTests]);
};
