import { getCookie, hasCookie, setCookie } from 'cookies-next';
import { assocPath, isEmpty, path } from 'ramda';

import config from '@/config';
import { pushToDataLayer } from '@/lib/gtm';
import {
  parseExperimentCookie,
  stringifyExperimentCookie,
} from '@/lib/utils/experiments';

type AbTastyCampaignId = number;

export type AbTastyTestsOnPage = Record<
  `${AbTastyCampaignId}`,
  {
    name: string;
    sub_type: 'ab' | 'mpt' | string;
    testDatas: { parentID: number };
    variationID: number;
    variationName: string;
  }
>;

export const getAbTastySrc = (): string => config.abTasty.scriptSrc;

export const handleAbTastyImpression = (
  pathname: string,
  abTastyTests: AbTastyTestsOnPage
): void => {
  if (isEmpty(abTastyTests)) return;

  // Massage the structure a little
  const campaigns = Object.entries(abTastyTests).map(
    ([campaignId, campaign]) => {
      const {
        sub_type: subType,
        testDatas: { parentID: parentId },
        variationID: variationId,
        variationName,
      } = campaign;
      return {
        campaignId: subType === 'mpt' ? String(parentId) : campaignId,
        pageCampaignId: campaignId,
        pageVariationId: String(variationId),
        variationId:
          variationName === 'Original'
            ? '0'
            : (variationName.split(' ').at(-1) as string),
        variationName,
      };
    }
  );

  // Send impressions to GTM for Piwik tracking
  campaigns.forEach((campaign) =>
    pushToDataLayer({
      event: 'gaEvent',
      eventAction: 'ab_tasty',
      eventCategory: 'experiment_impression',
      eventLabel: campaign.campaignId,
      experiment_id: campaign.campaignId,
      experiment_page_id: campaign.pageCampaignId,
      variant_id: `${campaign.campaignId}.${campaign.variationId}`,
      variant_name: campaign.variationName,
      variant_page_id: campaign.pageVariationId,
    })
  );

  // Update our cookie for sending to Salesforce later
  const structuredCookie = hasCookie('ih_ab_tasty')
    ? parseExperimentCookie(getCookie('ih_ab_tasty') as string)
    : {};

  const updatedStructure = campaigns
    .filter(({ campaignId }) => !path([pathname, campaignId], structuredCookie))
    .reduce(
      (acc, { campaignId, variationId }) =>
        assocPath([pathname, campaignId], variationId, acc),
      structuredCookie
    );

  const updatedCookie = stringifyExperimentCookie(updatedStructure);
  setCookie('ih_ab_tasty', updatedCookie);
};
