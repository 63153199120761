import { useSessionStorage } from 'react-use';

import config from '@/config';

type ExperimentApplication = {
  experimentId: string;
  isNewFormat: boolean;
  salesforceId: string;
  variantId: string;
};

type ExperimentKey = 'valueProp';

type ExperimentReferral =
  | `application:::${ExperimentKey}:::${string}:::${string}~${number}`
  | '';

const prodToPreviewSalesforceIds: Record<string, string> = {
  // Barcelona Web PT
  '01t5J0000023i45QAA': '01t0Q00000BN4ROQA1',
  // Paris Web PT
  '01t5J0000023i0hQAA': '01t0Q00000BN4REQA1',
};

export const realToFakeSalesforceIds: Record<string, string> = {
  // -PREVIEW-
  // Barcelona Web PT
  '01t0Q00000BN4ROQA1': '01t0Q00000BN4RTQA1',
  // Paris Web PT
  '01t0Q00000BN4REQA1': '01t0Q00000BN4RJQA1',

  // -PRODUCTION-
  // Barcelona Web PT
  '01t5J0000023i45QAA': '01t5J000002lXCmQAM',
  // Paris Web PT
  '01t5J0000023i0hQAA': '01t5J000002lXCcQAM',
};

export const useExperimentApplication = (
  experimentKey: ExperimentKey,
  realSalesforceId: string
): ExperimentApplication => {
  let experimentId = '';
  let isNewFormat = false;
  let salesforceId = realSalesforceId;
  let variantId = '';

  const [formReferrer] = useSessionStorage<ExperimentReferral>(
    'formReferrer',
    '',
    true
  );
  const expectedValues = ['application', experimentKey, salesforceId, ''];

  if (!formReferrer.startsWith(expectedValues.join(':::'))) {
    return { experimentId, isNewFormat, salesforceId, variantId };
  }

  const experimentCode = formReferrer.split(':::').at(-1) as string;
  [experimentId, variantId] = experimentCode.split('~');

  // Preview is serving the wrong IDs so convert them
  if (config.env !== 'production') {
    salesforceId = prodToPreviewSalesforceIds[salesforceId] || salesforceId;
  }
  // Convert IDs if the user applied to the 20-week course
  if (variantId === '2' && realToFakeSalesforceIds[salesforceId]) {
    salesforceId = realToFakeSalesforceIds[salesforceId];
    isNewFormat = true;
  }

  return { experimentId, isNewFormat, salesforceId, variantId };
};
