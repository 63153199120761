'use client';

import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const usePageLoad = () => {
  let onStart: () => void;
  let onComplete: () => void;

  const router = useRouter();

  useEffect(() => {
    // Handle first page load logic
    if (onStart) onStart();
    if (onComplete) onComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleChangeStart = (url, { shallow }) => {
      if (!shallow && onStart) onStart();
    };
    const handleChangeComplete = (url, { shallow }) => {
      if (!shallow && onComplete) onComplete();
    };

    // Subscribe to route changes for page load logic after the first
    router.events.on('routeChangeStart', handleChangeStart);
    router.events.on('routeChangeComplete', handleChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleChangeStart);
      router.events.off('routeChangeComplete', handleChangeComplete);
    };
  }, [onComplete, onStart, router]);

  return {
    onComplete: (completeFn: () => void) => {
      onComplete = completeFn;
    },
    onStart: (startFn: () => void) => {
      onStart = startFn;
    },
  };
};
