'use client';

import { createContext } from 'react';

import type { Language, Region } from '@/types';
import type { DatoLocale } from '@/lib/datocms';

import type { ReactElement, ReactNode } from 'react';

export type LanguageOption = { language: Language; path: string };
export type BasePageData = {
  gaPageType: string;
  gaCategory: string;
  languageOptions: LanguageOption[];
};
export type PageDataContext<
  TCmsData extends Record<string, unknown>,
  TPageData extends Record<string, unknown>
> = {
  params: {
    language: Language;
    region: Region;
    pathParts?: string[];
    slug?: string;
    page?: string;
  };
  locale: DatoLocale;
  cmsData: TCmsData;
  pageData: TPageData & BasePageData;
};

type PageContextProviderProps<
  TCmsData extends Record<string, unknown>,
  TPageData extends Record<string, unknown>
> = {
  children: ReactNode;
  pageContext: PageDataContext<TCmsData, TPageData>;
};

export const PageContext =
  createContext<
    PageDataContext<Record<string, unknown>, Record<string, unknown>>
  >(null);

export function PageContextProvider<
  TCmsData extends Record<string, unknown>,
  TPageData extends Record<string, unknown>
>(props: PageContextProviderProps<TCmsData, TPageData>): ReactElement {
  const { children, pageContext } = props;
  return (
    <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>
  );
}
