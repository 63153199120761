'use client';

import { ReactNode, createContext } from 'react';

export const FeatureToggleContext = createContext({
  enabledFeatures: {} as Record<string, boolean>,
});

type FeatureToggleProps = {
  children: ReactNode;
  enabledFeatures: Record<string, boolean>;
};

export const FeatureToggleProvider = ({
  children,
  enabledFeatures,
}: FeatureToggleProps) => (
  <FeatureToggleContext.Provider value={{ enabledFeatures }}>
    {children}
  </FeatureToggleContext.Provider>
);
