export const contactFormTags: Record<
  string,
  { eventAction: string; eventCategory?: string }
> = {
  // from Alumni Page
  al: {
    eventAction: 'send more info request',
    eventCategory: 'Testimonials',
  },
  // from Campus Page
  cm: {
    eventAction: 'send more info request',
    eventCategory: 'CampusPage',
  },
  default: {
    eventAction: 'send more info request',
  },
  // from Footer button
  fb: {
    eventAction: 'footer cta_send more info request',
  },
  // from Footer 'Contact' link
  fc: {
    eventAction: 'footer link_send more info request',
  },
  // from Financing Page
  fn: {
    eventAction: 'send more info request',
    eventCategory: 'Financing',
  },
  // from Navbar button
  nb: {
    eventAction: 'nav menu_send more info request',
  },
};

export const syllabusFormTags: Record<
  string,
  { eventCategory?: string; labelKey: 'campus' | 'region' }
> = {
  // from Course Page
  cp: {
    eventCategory: 'CoursePage',
    labelKey: 'region',
  },
  default: {
    labelKey: 'campus',
  },
  // from PDP
  pd: {
    eventCategory: 'PDP',
    labelKey: 'campus',
  },
};
