const getCourseVariant = (course: string) => ({
  filledTrack: { bg: `background.${course}` },
  track: { bgColor: 'fill.light', h: '8px', borderRadius: '4px' },
});

export const Progress = {
  parts: ['label', 'filledTrack', 'track'],
  variants: {
    story: {
      filledTrack: {
        bg: 'background.white',
      },
      track: {
        bgColor: 'stroke.light',
        h: '4px',
      },
    },
    cyber: getCourseVariant('cyber'),
    data: getCourseVariant('data'),
    ux: getCourseVariant('ux'),
    web: getCourseVariant('web'),
  },
};
