import { useContext } from 'react';

import { PageContext, PageDataContext } from '@/contexts';

import type { Context } from 'react';

export function usePageContext<
  TCmsData extends Record<string, unknown>,
  TPageData extends Record<string, unknown> = Record<string, never>
>(): PageDataContext<TCmsData, TPageData> {
  const context = useContext<PageDataContext<TCmsData, TPageData>>(
    PageContext as unknown as Context<PageDataContext<TCmsData, TPageData>>
  );
  if (!context) {
    throw new Error('useMyContext must be used under PageContextProvider');
  }
  return context;
}
