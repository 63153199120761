import { useLocalStorage } from 'react-use';

type GtmLocalStorage = {
  timezone?: string;
  countryName: string;
  applicationData: Partial<{
    campus: string;
    course: string;
    email: string;
    firstName: string;
    format: string;
    lastName: string;
    phoneNumber: string;
  }>;
};

export const useGtmLocalStorage = (): [
  GtmLocalStorage,
  (newGtmStorage: GtmLocalStorage) => void
] => {
  const [timezone, setTimezone] = useLocalStorage('timezone', '', {
    raw: true,
  });
  const [countryName, setCountryName] = useLocalStorage('countryName', '', {
    raw: true,
  });
  const [applicationData, setApplicationData] =
    useLocalStorage('applicationData');

  const setGtmStorage = ({
    // eslint-disable-next-line new-cap -- It's a JS native lib, so it doesn't apply
    timezone: newTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    countryName: newCountryName,
    applicationData: newApplicationData,
  }: GtmLocalStorage) => {
    setTimezone(newTimezone);
    setCountryName(
      newCountryName.length === 2
        ? new Intl.DisplayNames('en', { type: 'region' }).of(
            newCountryName.toUpperCase()
          )
        : newCountryName
    );
    setApplicationData(newApplicationData);
  };

  return [{ applicationData, countryName, timezone }, setGtmStorage];
};
