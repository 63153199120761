import Bugsnag from '@bugsnag/js';
import { pick } from 'ramda';

import config from '@/config';
import type { NextRequest } from '@/lib/api-helpers';

import type { BugsnagPluginAwsLambdaHandler } from '@bugsnag/plugin-aws-lambda';
import type { Plugin } from '@bugsnag/js';

export function startBugsnag(): void {
  if (process.env.NEXT_PHASE !== 'phase-production-build') {
    const plugins = process.env.NEXT_IS_SERVER
      ? // eslint-disable-next-line @typescript-eslint/no-var-requires
        [require('@bugsnag/plugin-aws-lambda') as Plugin]
      : [];
    Bugsnag.start({
      apiKey: config.bugsnag.apiKey,
      appVersion: process.env.NEXT_BUILD_ID,
      enabledReleaseStages: ['production', 'preview'],
      plugins,
      releaseStage: config.env,
    });
  }
}

export function getServerlessHandler(): BugsnagPluginAwsLambdaHandler {
  return Bugsnag.getPlugin('awsLambda').createHandler();
}

export function reportError(error: Error, request: NextRequest): Promise<void> {
  return new Promise((resolve) => {
    Bugsnag.notify(
      error,
      (event) => {
        event.context = `${request.method} ${request.url}`;
        event.severity = 'error';
        event.unhandled = true;
        event.clearMetadata('AWS Lambda context');
        event.addMetadata(
          'Request',
          pick(['body', 'headers', 'method', 'url', 'query'], request)
        );
      },
      () => {
        resolve();
      }
    );
  });
}
