import { useEffect, useState } from 'react';

export const usePollingEffect = <PolledValue = unknown>(
  pollingFunction: () => PolledValue,
  equalsFunction: (a: PolledValue, b: PolledValue) => boolean,
  interval: number
): PolledValue => {
  const [value, setValue] = useState<PolledValue>(pollingFunction());

  useEffect(() => {
    const timerId = setInterval(() => {
      const maybeNewValue = pollingFunction();
      if (equalsFunction(maybeNewValue, value)) return;

      setValue(maybeNewValue);
    }, interval);

    return () => clearInterval(timerId);
  }, [equalsFunction, interval, pollingFunction, setValue, value]);

  return value;
};
