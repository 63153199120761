import { useContext } from 'react';

import { FeatureToggleContext } from '@/contexts';

export const useFeatureToggle = () => {
  const { enabledFeatures } = useContext(FeatureToggleContext);

  const isEnabled = (featureName: string) => enabledFeatures[featureName];

  return { isEnabled };
};
