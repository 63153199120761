import { isEmpty } from 'ramda';

import config from '@/config';

export type GtmEvent = {
  event?: string;
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
} & GtmEventCustomDimensions;

export type GtmEventCustomDimensions = {
  email?: string;
  pageType?: string;
  campus?: string;
  course?: string;
  format?: string;
};

export const pushToDataLayer = (object: Record<string, unknown>): void => {
  if (window.dataLayer) {
    window.dataLayer.push(object);
  }
};

export const sendEvent = (event: GtmEvent): void => {
  pushToDataLayer({ event: 'gaEvent', ...event });
};

export const getIframeUrl = (): string => {
  let gtmParams = '';
  if (!isEmpty(config.gtm.environment)) {
    const { gtmAuth, gtmPreview } = config.gtm.environment;
    gtmParams = `&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x`;
  }
  return `${config.gtm.serverUrl}/ns.html?id=${config.gtm.id}${gtmParams}`;
};

export const getScript = (): string => {
  let gtmParams = '';
  if (!isEmpty(config.gtm.environment)) {
    const { gtmAuth, gtmPreview } = config.gtm.environment;
    gtmParams = `+"&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x"`;
  }
  return `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    '${config.gtm.serverUrl}/sktbmdly.js?id='+i+dl${gtmParams};f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${config.gtm.id}')
  `;
};
