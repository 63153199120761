import { DesignSystemProvider } from '@ironhack/design-system2/components';
import { getCookie, hasCookie } from 'cookies-next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import { useSessionStorage } from 'react-use';

import { FeatureToggleProvider } from '@/contexts';
import { useAbTastyTests, usePageLoad } from '@/hooks';
import { startBugsnag } from '@/lib/bugsnag';
import { getScript, pushToDataLayer } from '@/lib/gtm';
import type { PageProps } from '@/pages/[region]/[language]/[[...pathParts]]';
import featureToggles from '../config/feature-toggles';
import theme from '../theme';

import type { AppProps as NextAppProps } from 'next/app';

type AppProps = Omit<NextAppProps<PageProps>, 'pageProps'> & {
  pageProps: PageProps;
};

startBugsnag();

function MyApp({ Component, pageProps }: AppProps): React.ReactElement {
  const [, setPreviousPageType] = useSessionStorage('pageTypePrevious');
  const [nextPageType, setNextPageType] = useSessionStorage('pageTypeNext');
  const pageType = pageProps.pageData?.gaPageType || 'Unknown';
  const router = useRouter();

  const pathname = router.asPath.split('?')[0];
  useAbTastyTests(pathname);

  const { onStart, onComplete } = usePageLoad();
  onStart(() => setPreviousPageType(nextPageType));
  onComplete(() => {
    setNextPageType(pageType);
    pushToDataLayer({ event: 'pageView', pageType });

    // Report Optimize experiment
    if (hasCookie('gaexp_server')) {
      const experimentCookie = getCookie('gaexp_server') as string;
      const activeExperiment = experimentCookie
        .split('|')
        .find((exp) => exp.startsWith(`${pathname}.`));
      if (activeExperiment) {
        const [experimentId, variant] = (
          activeExperiment.split('.').at(-1) as string
        ).split('~');
        pushToDataLayer({
          event: 'gaEvent',
          eventAction: 'google_optimize',
          eventCategory: 'experiment_impression',
          eventLabel: experimentId,
          experiment_id: experimentId,
          variant_id: `${experimentId}.${variant}`,
        });
      }
    }
  });

  useEffect(() => {
    const htmlElement = document.querySelector('html');
    if (htmlElement) htmlElement.lang = pageProps.params?.language;
  }, [pageProps.params?.language]);

  return (
    <>
      <Head>
        <meta
          content="width=device-width, initial-scale=1.0, maximum-scale=5"
          name="viewport"
        />
      </Head>
      <FeatureToggleProvider enabledFeatures={featureToggles}>
        <DesignSystemProvider theme={theme}>
          <Component {...pageProps} />
        </DesignSystemProvider>
      </FeatureToggleProvider>
      <Script
        dangerouslySetInnerHTML={{
          __html: getScript(),
        }}
        id="gtm-base"
        strategy="afterInteractive"
      />
      <Script
        src="https://tracker.gaconnector.com/gaconnector.js"
        strategy="lazyOnload"
      />
    </>
  );
}

export default MyApp;
