import { addWeeks, differenceInWeeks } from 'date-fns';
import { flatten, unflatten } from 'flat';
import {
  __,
  curry,
  filter,
  findLastIndex,
  fromPairs,
  join,
  map,
  pipe,
  propEq,
  split,
  toPairs,
} from 'ramda';

import type { PageDataContext, WebCohort } from '@/types';

export const getExperimentFilters = ({
  experimentId,
  variantId,
}: PageDataContext['experiment']) => {
  if (!experimentId || !variantId) {
    return 'experiment: { exists: false }, variant: { exists: false },';
  }

  // return filters with fallback in case no page with experiment is published
  return `
    OR: [
      {
        experiment: { eq: ${experimentId} }
        variant: { eq: ${variantId} },
      },
      { experiment: { exists: false } },
    ],
  `;
};

export const hackCohortFor20Weeks = (cohort: WebCohort): WebCohort => {
  // Change end date to adjust to 20 weeks
  const realEndDate = new Date(cohort.end_date);
  const numberOfWeeks = differenceInWeeks(
    realEndDate,
    new Date(cohort.start_date),
    { roundingMethod: 'ceil' }
  );
  const endDate = addWeeks(realEndDate, 20 - numberOfWeeks);

  // Change Saturday end time from 17:00 to 16:00
  let timetable = cohort.timetable;
  if (timetable) {
    const weekendIndex = findLastIndex(Boolean, timetable);
    const weekendTime = timetable[weekendIndex] as Exclude<
      WebCohort['timetable'][number],
      null
    >;
    const endParts = weekendTime.end_time.split(':');
    timetable = [...timetable];
    timetable[weekendIndex] = {
      ...weekendTime,
      end_time: `${Number(endParts[0]) - 1}:${endParts[1]}`,
    };
  }

  return {
    ...cohort,
    end_date: endDate.toISOString(),
    timetable,
  };
};

// Converts a string like '/fr/fr.1234~0|/es/es.5678~2|/fr/fr.910~1'
// to an object like:
// {
//   '/fr/fr': { 1234: '0', 910: '1' },
//   '/es/es': { 5678: '2' },
// }
export const parseExperimentCookie = pipe<
  [string],
  string[],
  string[][],
  Array<[string, string]>,
  Record<string, string>,
  Record<string, Record<string, string>>
>(
  split('|'),
  map(split('~')),
  filter(propEq('length', 2)) as (pairs: string[][]) => Array<[string, string]>,
  fromPairs,
  curry(unflatten)(__, { object: true })
);

// The opposite of the above function
export const stringifyExperimentCookie = pipe<
  [Record<string, Record<string, string>>],
  Record<string, string>,
  Array<[string, string]>,
  string[],
  string
>(flatten, toPairs, map(join('~')), join('|'));
