import { usePathname } from 'next/navigation';

import { usePageContext } from '@/hooks';

type FormSubmission = {
  data: Record<string, unknown>;
  experimentId?: string;
  formType: string;
  variantId?: string;
};

export const useFormRequest = (): ((
  form: FormSubmission
) => Promise<Response>) => {
  const {
    pageData: { experimentId, variantId },
  } = usePageContext();
  const referrer = usePathname();

  return async (form: FormSubmission): Promise<Response> => {
    const extendedForm = { experimentId, referrer, variantId, ...form };

    const response = await fetch('/api/forms', {
      body: JSON.stringify(extendedForm),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });
    if (!response.ok) {
      throw new Error('Error sending form data to Salesforce');
    }
    return response;
  };
};
